/* eslint-disable sonarjs/cognitive-complexity */
import { useAuth } from '@context/AuthContext';
import { useNotification } from '@context/NotificationContext';
import useSetSearchParams from '@core/hooks/useSetSearchParams';
import { appCookies } from '@core/utils/appCookies';
import { getChildrenOnDisplayName } from '@core/utils/getChildrenOnDisplayName';
import { BranchInterface } from '@interface/UserInterface';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, NavbarInterface } from 'uangcermat-web-toolkit-v2';

interface ContentLayout extends NavbarInterface {
  pageTitle?: string;
  children: React.ReactNode;
  leftComponent?: React.ReactNode;
  isListBranch?: boolean;
  isChangeBranch?: boolean;
  pathChangeBranch?: string;
  beforChangeBranch?: () => void;
}

const ContentLayout = ({
  isShowClose,
  onClickClose,
  containerStyles,
  pageTitle,
  leftComponent,
  children,
  isListBranch = true,
  isChangeBranch,
  pathChangeBranch,
  beforChangeBranch
}: ContentLayout) => {
  const router = useRouter();
  const { t } = useTranslation(['payComponent', 'common']);
  const { searchParams, createQueryParams, setSearchParams } = useSetSearchParams();
  const { listBranch, activeBranch, setActiveBranch, activeCompany } = useAuth();

  const { activeBranchId } = useNotification();
  const { setCookie, getCookie } = appCookies();

  useEffect(() => {
    (async () => {
      const authInfoLocation = await getCookie({
        name: 'authInfoLocation'
      });

      const lId = searchParams.get('lId');

      const selectedBranch: BranchInterface =
        authInfoLocation && authInfoLocation !== 'undefined' && JSON.parse(authInfoLocation);

      const checkedBranch = listBranch.some(
        (data) => data?.id === selectedBranch?.id || activeBranchId
      );

      if (listBranch && listBranch.length > 0) {
        if (checkedBranch) {
          let branchCompanies: BranchInterface[] = [];
          if (lId) {
            branchCompanies = listBranch.filter((branch) => branch.id === lId);
          } else if (selectedBranch) {
            branchCompanies = listBranch.filter((branch) => branch.id === selectedBranch.id);
          }
          const branchCompany = branchCompanies[0] || listBranch[0];
          setActiveBranch(branchCompany);
          setSearchParams('lId', branchCompany.id);
        } else {
          setActiveBranch(listBranch[0]);
          setSearchParams('lId', listBranch[0]?.id);
          await setCookie({
            name: 'authInfoLocation',
            value: JSON.stringify(listBranch[0])
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBranch, activeBranchId]);

  const title = getChildrenOnDisplayName(children, 'Title');
  const content = getChildrenOnDisplayName(children, 'Content');

  const headTitle = pageTitle
    ? `${pageTitle} -  ${process.env.APP_NAME} Client Portal`
    : `${process.env.APP_NAME} Client Portal`;

  return (
    <>
      <Head>
        <title>{headTitle}</title>
        <meta name="description" content={headTitle} />
        <meta name="keywords" content={headTitle} />
      </Head>
      <div className="flex flex-col w-full">
        <Navbar
          isShowClose={isShowClose}
          onClickClose={() => onClickClose?.()}
          containerStyles={containerStyles}
        >
          {leftComponent ? (
            leftComponent
          ) : !title ? (
            <Navbar.Title label={pageTitle} />
          ) : (
            <Navbar.Title>{title}</Navbar.Title>
          )}
          {isListBranch && listBranch && listBranch.length > 0 && (
            <Navbar.Switcher
              data={listBranch}
              label={activeBranch && activeBranch.name}
              labelStyles="font-normal leading-4"
              tableLabel={t('menu:setting.branch')}
              selectedBranchId={activeBranch && activeBranch.id}
              itemsContainerStyles="shadow-lg z-50 w-[240px] h-[301px] border rounded"
              containerStyles="w-[146px] h-8"
              position="bottom-end"
              onSelectItem={async ({ id }) => {
                if (beforChangeBranch) {
                  beforChangeBranch();
                }

                const value = listBranch.filter((branch) => branch.id === id);
                await setCookie({
                  name: 'authInfoLocation',
                  value: JSON.stringify(value[0])
                });
                setActiveBranch(value[0]);

                if (isChangeBranch && pathChangeBranch) {
                  if (pathChangeBranch.includes('?')) {
                    router.push(`${pathChangeBranch}${createQueryParams('lId', id)}`, undefined, {
                      shallow: true
                    });
                  } else {
                    router.push(`${pathChangeBranch}?${createQueryParams('lId', id)}`),
                      undefined,
                      {
                        shallow: true
                      };
                  }
                } else {
                  createQueryParams('lId', id);
                  await router.push(`${router.pathname}?cId=${activeCompany?.id}`, undefined, {
                    shallow: false
                  });
                }
              }}
            />
          )}
        </Navbar>
        {content}
      </div>
    </>
  );
};

const Title = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
Title.displayName = 'Title';
ContentLayout.Title = Title;

const Content = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
Content.displayName = 'Content';
ContentLayout.Content = Content;

export default ContentLayout;
