import ContentLayout from '@layout/ContentLayout';
import React from 'react';

function IndexPage() {
  return (
    <ContentLayout>
      <></>
    </ContentLayout>
  );
}

export default IndexPage;
