import React from 'react';

export const getChildrenOnDisplayName = (
  children: React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayName: any
) => {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.find((child) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((child as React.ReactElement).type as any).displayName === displayName;
  });
};
